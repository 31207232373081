import { OptionTypeDatasBrutes, PlanType } from '@/shared/types/plan'

const getPlanByType = (planData: any, type: string) => {
  const planDataByType = planData.find((plan) => plan.type === type)

  if (!planDataByType) {
    return null
  }
  return {
    price: planDataByType.price,
    go: planDataByType.nationalData.fairuse,
    roaming: planDataByType.roamingData.fairuse
  }
}

export const getDataPlans = ({ planData, boosters, isSubscriber }) => {
  const free2EuroPlanData = getPlanByType(planData, 'eur2')
  const serieFreePlanData = getPlanByType(planData, 'serieFree')
  const free5GPlanData = getPlanByType(planData, 'free')

  const emptySpaceForAlignment: OptionTypeDatasBrutes = {
    label: ''
  }
  const emptySpacesForBoosterAlignment: OptionTypeDatasBrutes[] = []
  boosters.forEach((_: never) => {
    emptySpacesForBoosterAlignment.push(emptySpaceForAlignment)
  })

  const PLANS: PlanType[] = []
  if (free2EuroPlanData) {
    PLANS.push({
      name: '2€',
      subtitleDesktop: '2h',
      subtitleMobile: "2h d'appel",
      monthlyPrice: isSubscriber ? 0 : free2EuroPlanData.price / 100,
      monthlyDiscount: 2,
      monthlyWithDiscount: isSubscriber ? free2EuroPlanData.price / 100 : 0,
      options: [
        {
          label: 'Internet **50 Mo** en 4G/4G+',
          icon: '/assets/icons/offers/4G+.svg'
        },
        {
          label: 'Appels **2h**',
          icon: '/assets/icons/offers/mobile.svg'
        },
        {
          label: 'SMS & MMS **illimités**',
          icon: '/assets/icons/offers/message.svg'
        },
        {
          label: 'Depuis Europe/DOM **Internet 50 Mo** en 4G',
          detail: 'Appels 2h (décomptés du forfait) et SMS, MMS illimités depuis Europe & DOM.',
          icon: '/assets/icons/offers/plane.svg'
        },
        ...boosters.map((booster) => ({
          label: `Option Booster **${booster.volume} et appels illimités en France Métropolitaine**`,
          booster: booster.amount,
          icon: '/assets/icons/offers/booster.svg'
        }))
      ],
      link: 'https://mobile.free.fr/fiche-forfait-2-euros'
    })
  }
  if (serieFreePlanData && free5GPlanData) {
    PLANS.push({
      name: 'Serie Free',
      subtitleDesktop: `${serieFreePlanData.go / 1000} Go`,
      subtitleMobile: `${serieFreePlanData.go / 1000} Go`,
      monthlyPrice: serieFreePlanData.price / 100,
      monthlyWithDiscount: isSubscriber ? (free5GPlanData.price - 400) / 100 : free5GPlanData.price / 100,
      options: [
        {
          label: `Internet **${serieFreePlanData.go / 1000} Go** en 5G/4G+`,
          icon: '/assets/icons/offers/4G+.svg'
        },
        {
          label: 'Appels **illimités**',
          icon: '/assets/icons/offers/mobile.svg'
        },
        {
          label: 'SMS & MMS **illimités**',
          icon: '/assets/icons/offers/message.svg'
        },
        {
          label: `Depuis Europe/DOM Internet **${serieFreePlanData.roaming / 1000} Go** en 4G`,
          detail: 'Appels, SMS, MMS illimités depuis Europe & DOM.',
          icon: '/assets/icons/offers/plane.svg'
        },
        {
          label: 'Appli **TV OQEE by Free** incluse',
          icon: '/assets/icons/offers/oqee_by_free.svg'
        },
        emptySpaceForAlignment,
        ...emptySpacesForBoosterAlignment
      ],
      link: 'https://mobile.free.fr/fiche-forfait-serie-free'
    })
  }
  if (free5GPlanData) {
    PLANS.push({
      name: 'Free 5G',
      subtitleDesktop: isSubscriber ? 'illimité' : `${free5GPlanData.go / 1000} Go`,
      subtitleMobile: '5G/4G+',
      monthlyPrice: isSubscriber ? (free5GPlanData.price - 1000) / 100 : free5GPlanData.price / 100,
      monthlyDiscount: 10,
      monthlyWithDiscount: isSubscriber ? (free5GPlanData.price - 400) / 100 : (free5GPlanData.price - 1000) / 100,
      options: [
        {
          label: !isSubscriber
            ? `Internet **${free5GPlanData.go / 1000} Go** en **5G/4G+**`
            : 'Internet **illimité** en **5G/4G+**',
          icon: '/assets/icons/offers/5G.svg'
        },
        {
          label: 'Appels **illimités**',
          icon: '/assets/icons/offers/mobile.svg'
        },
        {
          label: 'SMS & MMS **illimités**',
          icon: '/assets/icons/offers/message.svg'
        },
        {
          label: `Depuis l'étranger **Internet ${free5GPlanData.roaming / 1000} Go** en 4G dans + de 100 destinations`,
          detail: 'Appels, SMS et MMS illimités depuis Europe, DOM, Canada, Australie, Israël, USA, Afrique du Sud...',
          icon: '/assets/icons/offers/plane.svg'
        },
        {
          label: 'Appli **TV OQEE by Free** incluse',
          icon: '/assets/icons/offers/oqee_by_free.svg'
        },
        ...emptySpacesForBoosterAlignment
      ],
      link: 'https://mobile.free.fr/fiche-forfait-free'
    })
  }

  return PLANS
}

export const getDelayedPriceWording = (name: string, monthlyWithDiscount: number, isSubscriber: boolean): string => {
  switch (name) {
    case '2€':
      return isSubscriber
        ? `pour les abonnés Freebox au lieu de ${monthlyWithDiscount}€/mois`
        : `${monthlyWithDiscount}€/mois pour les abonnés Freebox`
    case 'Serie Free':
      return isSubscriber
        ? `pendant 1 an, puis Forfait Free 5G à ${monthlyWithDiscount}€/mois pour les abonnés Freebox`
        : `pendant 1 an, puis Forfait Free 5G à ${monthlyWithDiscount}€/mois`
    case 'Free 5G':
    default:
      return isSubscriber
        ? `pour les abonnés Freebox Pop, au lieu de ${monthlyWithDiscount}€/mois pour les autres abonnés Freebox`
        : `${monthlyWithDiscount}€/mois pour les abonnés Freebox Pop`
  }
}
