import clsx from 'clsx'
import { PriceAmount, PRICE_SIZE_CLASS } from './Price'
import { E_PRICE_SIZE, PriceProps } from './PriceTypes'

export const PriceMonthly = ({ amount, className, amountClassName, size = E_PRICE_SIZE.MEDIUM }: PriceProps) => {
  const priceSplit = String(amount).split('.')
  const amountDigit = priceSplit[0]
  const amountCents = priceSplit[1] || null

  const redAmountClassName = clsx('text-red', amountClassName)

  const centPriceClass = clsx('whitespace-nowrap font-iliad text-red text-left', PRICE_SIZE_CLASS[size].cent)

  const byMonthClass = clsx('text-left', PRICE_SIZE_CLASS[size].byMonth)

  return (
    <div className={clsx('flex items-center w-min mb-4', className)}>
      <PriceAmount className={redAmountClassName} size={size}>
        {amountDigit}
      </PriceAmount>
      <div className="ml-1">
        <p className={centPriceClass}>{amountCents ? `€${amountCents}` : '€'}</p>
        <p className={byMonthClass}>/mois</p>
      </div>
    </div>
  )
}
