import React from 'react'
import { isSubscriberType } from '@/pages'
import { getDataDetailItems } from '@/data/services-details'

const ServiceDetails = ({ isSubscriber }: isSubscriberType) => {
  const detailItems = getDataDetailItems({ isSubscriber })
  return (
    <section className="pt-6 laptop:pt-32">
      <h2 className="w-full text-center my-8 text-24 font-bold font-iliad laptop:text-40">
        Les avantages de <span className="text-red-500">Free Transfert{isSubscriber && ' Premium'}</span>
      </h2>

      <div className="flex flex-col laptop:flex-row justify-center flex-wrap">
        {detailItems.map(({ icon, label, tag }) => (
          <div
            key={label}
            className="flex flex-col items-center w-full my-2 text-14 laptop:text-16 laptop:mx-2 laptop:w-[calc(25%-16px)] border border-gray-100 rounded-12 p-8"
          >
            <img className="w-18 pb-2 laptop:pb-4" src={`/assets/icons/service-details/${icon}`} alt={icon} />
            <div className="flex flex-col justify-center items-center">
              {tag && (
                <span className="text-status-info text-center text-12 w-fit font-semibold bg-blue-50 border-none px-2 mb-2 rounded-full">
                  {tag}
                </span>
              )}
              <p className="text-center w-full">{label}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default ServiceDetails
