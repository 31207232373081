import { Title } from '@/components/ui/Title/Title'
import { isSubscriberType, StaticProps } from '@/pages'
import { getDataPlans } from '@/data/plans'
import { PlanCard } from './PlanCard'
import { PlanType } from '@/shared/types/plan'

const PlansMobile = ({ isSubscriber, planData, boosters }: isSubscriberType & StaticProps) => {
  const PLANS = getDataPlans({ planData, boosters, isSubscriber })

  return (
    <section className="pt-6">
      <Title title="Découvrez les forfaits mobiles Free" className="mt-8 mb-4 text-24 font-bold font-iliad" />

      <div className="flex flex-col">
        {PLANS.map(({ monthlyDiscount, monthlyPrice, options, name, subtitleMobile, link }: PlanType) => (
          <PlanCard
            monthlyDiscount={monthlyDiscount}
            monthlyPrice={monthlyPrice}
            options={options}
            name={name}
            subtitleMobile={subtitleMobile}
            link={link}
            isSubscriber={isSubscriber}
            key={`plans-mobile-${name}`}
          />
        ))}
      </div>
    </section>
  )
}

export default PlansMobile
