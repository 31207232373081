export const getDataDetailItems = ({ isSubscriber }) => {
  return [
    {
      icon: isSubscriber ? 'unlimited.svg' : 'files_10.svg',
      label: isSubscriber ? 'Taille des fichiers illimitée' : 'Taille du transfert jusqu’à 10 Go',
      tag: isSubscriber && 'Nouveau'
    },
    {
      icon: isSubscriber ? 'send_50.svg' : 'send_10.svg',
      label: isSubscriber ? 'Jusqu’à 50 destinataires' : 'Jusqu’à 10 destinataires'
    },
    {
      icon: isSubscriber ? 'calendar_30d.svg' : 'calendar_7d.svg',
      label: isSubscriber ? "Validité du transfert jusqu'à 30 jours" : 'Validité du transfert jusqu’à 7 jours'
    },
    { icon: 'locked.svg', label: 'Protection du transfert avec mot de passe' },
    { icon: 'fileToSend.svg', label: 'Partage par email ou par lien' },
    { icon: 'noAccount.svg', label: 'Aucun compte nécessaire' },
    { icon: 'unlimited.svg', label: 'Nombre de transferts illimité' }
  ]
}
