import { ComparatorBody } from '../Shared/ComparatorBody/ComparatorBody'

const ComparatorHome = () => {
  return (
    <section id="comparator" className="w-full mt-14 laptop:mt-32 bg-gray-50 rounded-16">
      <ComparatorBody />
    </section>
  )
}

export default ComparatorHome
