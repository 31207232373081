import React, { useState } from 'react'
import { IFAQItem, FAQ_SECTION } from '@/data/faq'
import { FAQItem } from './FAQItem'

const FAQ = () => {
  const [currentItemOpen, setCurrentItemOpen] = useState<number>(-1)

  return (
    <section className="py-6 laptop:mb-8 flex flex-col w-full laptop:flex-row content-start">
      <h2 className="font-bold text-24 text-center my-8 laptop:my-0 laptop:mr-8 mx-auto tablet:text-40 laptop:text-left laptop:w-11/12">
        Besoin d’aide pour votre <span className="text-red">transfert</span> ?
      </h2>

      <div>
        <ul className="p-0 m-0">
          {FAQ_SECTION.collection.map((item: IFAQItem, index: number) => (
            <FAQItem
              key={`faq-item-${item.question}`}
              id={index}
              {...item}
              itemOpen={currentItemOpen}
              setItemOpen={setCurrentItemOpen}
            />
          ))}
        </ul>
      </div>
    </section>
  )
}

export default FAQ
