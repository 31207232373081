import { ReactNode } from 'react'

export enum E_PRICE_SIZE {
  SMALL,
  MEDIUM,
  LARGE,
  EXTRA_LARGE
}

export type PriceAmountProps = {
  size: E_PRICE_SIZE
  children: ReactNode
  className?: string
}

export type PriceProps = Pick<PriceAmountProps, 'size'> & {
  amount?: number
  amountClassName?: string
  className?: string
}

export interface IPrice {
  amount: number
  details?: string
  byMonth?: boolean
}
