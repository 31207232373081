import { E_PRICE_SIZE, PriceMonthly } from '@/components/ui/Price'
import { Title } from '@/components/ui/Title/Title'
import { getDataPlans, getDelayedPriceWording } from '@/data/plans'
import { StaticProps, isSubscriberType } from '@/pages'
import { Button, E_ELEMENT_PARSE_STRING, ParseString } from '@transverse/digitalsystems-library'
import clsx from 'clsx'

const PlansDesktop = ({ isSubscriber, planData, boosters }: isSubscriberType & StaticProps) => {
  const PLANS = getDataPlans({ planData, boosters, isSubscriber })

  return (
    <section className="pt-6 laptop:pt-32">
      <Title title="Découvrez les forfaits mobiles Free" className="my-8 font-iliad laptop:text-40" />

      <div className="flex flex-col mt-6 w-full desktop-sm:flex-row laptop:flex-row">
        {PLANS.map(({ monthlyDiscount, monthlyWithDiscount, monthlyPrice, options, name, subtitleDesktop, link }) => (
          <div
            key={name}
            className={clsx(
              name === 'Serie Free' && 'laptop:mx-4',
              'flex flex-col justify-between text-center rounded-16 border border-gray-100 w-full mb-4 p-8 desktop-md:px-22 desktop-md:w-1/3'
            )}
          >
            {name !== 'Serie Free' ? (
              <h2 className="font-normal px-4 text-24 font-iliad text-gray-900">Forfait&nbsp;{name}</h2>
            ) : (
              <h2
                className={clsx(
                  'font-normal px-4 text-24 font-iliad text-gray-900 laptop:-mb-2',
                  isSubscriber && 'laptop:-mb-[25px]'
                )}
              >
                {name}
              </h2>
            )}

            <Title
              title={subtitleDesktop}
              className="flex font-bold w-full px-4 pb-1 text-40 text-center font-iliad text-gray-900"
            />

            {isSubscriber && monthlyDiscount && (
              <span className="text-status-success text-center self-center text-14 w-fit font-semibold bg-[#DCFCE7] border-none px-[12px] my-2 rounded-full">
                {monthlyDiscount}€ d'économies par mois
              </span>
            )}

            <div className="flex flex-col mt-6 text-left">
              {options.map(({ label, detail, icon, booster }, index) => (
                <div className="flex my-2" key={`plans-desktop-${index}`}>
                  {icon ? (
                    <img src={icon} alt="icon" className="w-8 h-8 mr-4" />
                  ) : (
                    <span className={isSubscriber ? 'h-4' : 'h-14'} />
                  )}
                  <div className="flex flex-col">
                    <ParseString type={E_ELEMENT_PARSE_STRING.SPAN} className="text-14">
                      {label}
                    </ParseString>
                    <p className="text-12">{detail}</p>
                    {booster && <p className="text-14 text-red-500 font-bold">+{booster}€/mois</p>}
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-col items-center mt-8">
              <PriceMonthly amount={monthlyPrice} size={E_PRICE_SIZE.SMALL} />
              <p className="text-12 text-center">{getDelayedPriceWording(name, monthlyWithDiscount, isSubscriber)}</p>
              <p className="text-12 text-center mt-2 text-gray-900">
                <strong className="font-semibold">Sans engagement</strong>
              </p>
              <Button label="S'abonner" onClick={() => window.open(link, '_blank')} className="w-full mt-6" />
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default PlansDesktop
