import { E_PRICE_SIZE, PriceAmountProps } from './PriceTypes'
import clsx from 'clsx'

export const PRICE_SIZE_CLASS: Record<
  E_PRICE_SIZE,
  {
    amount: string
    cent: string
    byMonth: string
  }
> = {
  [E_PRICE_SIZE.SMALL]: {
    amount: 'text-[48px]',
    cent: 'text-18 leading-[18px]',
    byMonth: 'text-14 leading-[14px] mt-[2px]'
  },
  [E_PRICE_SIZE.MEDIUM]: {
    amount: 'text-64',
    cent: 'text-[26px] leading-[26px]',
    byMonth: 'text-20 leading-[20px] mt-1'
  },
  [E_PRICE_SIZE.LARGE]: {
    amount: 'text-[80px]',
    cent: 'text-32 leading-[32px]',
    byMonth: 'text-24 leading-[24px] mt-2'
  },
  [E_PRICE_SIZE.EXTRA_LARGE]: {
    amount: 'text-[96px]',
    cent: 'text-40 leading-[40px]',
    byMonth: 'text-32 leading-[32px] mt-2'
  }
}

export const PriceAmount = ({ children, size, className }: PriceAmountProps) => {
  return (
    <p className={clsx('h-min leading-90% whitespace-nowrap font-iliad', PRICE_SIZE_CLASS[size].amount, className)}>
      {children}
    </p>
  )
}
