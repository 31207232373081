import { Attributes, createElement, Fragment } from 'react'

import { ETagType } from '@/shared/types'
import parseStringToJsx from './ParseString.helpers'

interface IParseStringProps {
  type?: ETagType
  className?: string
  children?: string
  style?: object
}

export const ParseString = ({
  type = ETagType.P,
  className = 'parsed-paragraph',
  children,
  style
}: IParseStringProps) => {
  const isFragment = type === ETagType.FRAGMENT

  return createElement(
    isFragment ? Fragment : type,
    isFragment ? {} : ({ className, style } as Attributes),
    parseStringToJsx(children)
  )
}
