import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { AppContext } from '@/shared/contexts/AppContext'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import { BoosterType, PlanInType } from '@/shared/types/plan'
import { BOOSTER_DATA_URL, PLANS_DATA_URL } from '@/shared/constants'
import FAQ from '@/components/templates/Shared/FAQ'
import ComparatorHome from '@/components/templates/Home/Comparator'
import Presentation from '@/components/templates/Home/Presentation'
import ServiceDetails from '@/components/templates/Home/ServiceDetails'
import PlansDesktop from '@/components/templates/Home/PlansComponents/PlansDesktop'
import PlansMobile from '@/components/templates/Home/PlansComponents/PlansMobile'

export type isSubscriberType = { isSubscriber: boolean }

export type StaticProps = {
  planData: PlanInType[]
  boosters: BoosterType[]
}

export const getStaticProps = async () => {
  const planData = await fetch(PLANS_DATA_URL).then((res) => res.json())
  const boosters = await fetch(BOOSTER_DATA_URL).then((res) => res.json())

  return {
    revalidate: 60,
    props: {
      planData,
      boosters
    }
  }
}

const HomePage = ({ planData, boosters }: StaticProps) => {
  const { isSubscriber } = useContext(AppContext)
  const { isMobile } = useBreakpoints()
  const router = useRouter()
  const [displayHomePage, setDisplayHomePage] = useState<boolean>()

  useEffect(() => {
    if (!localStorage.getItem('info')) {
      setDisplayHomePage(true)
      localStorage.setItem('info', '1')
    } else {
      router.push('/upload')
    }
  }, [])

  return (
    <>
      {displayHomePage && (
        <div className="flex flex-col items-center justify-center p-4 pt-0 w-full laptop:max-w-7xl">
          <Presentation isSubscriber={isSubscriber} />
          <ServiceDetails isSubscriber={isSubscriber} />

          {!isSubscriber && <ComparatorHome />}

          {isMobile ? (
            <PlansMobile isSubscriber={isSubscriber} planData={planData} boosters={boosters} />
          ) : (
            <PlansDesktop isSubscriber={isSubscriber} planData={planData} boosters={boosters} />
          )}
          <div className="pt-6 laptop:pt-32">
            <FAQ />
          </div>
        </div>
      )}
    </>
  )
}

export default HomePage
