import React from 'react'
import { Title } from '@/components/ui/Title/Title'
import { Button, Icon, IconNameType } from '@transverse/digitalsystems-library'
import { useRouter } from 'next/router'
import { isSubscriberType } from '@/pages'
import Lottie from 'react-lottie'
import * as animationData from '../../../../public/assets/animation/Free_transfert_Lottie_01.json'

type DetailItemType = {
  icon: IconNameType
  label: string
  tag?: string
}

const DETAIL_ITEMS: DetailItemType[] = [
  {
    icon: 'file-2-line',
    label: '1. Sélectionnez vos fichiers à envoyer'
  },
  {
    icon: 'file-download-line',
    label: '2. Choisissez vos options de transfert'
  },
  { icon: 'checkbox-circle-line', label: '3. Validez et c’est envoyé !' }
]

const Presentation = ({ isSubscriber }: isSubscriberType) => {
  const router = useRouter()
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <>
      <div className="flex flex-col items-center">
        <Title
          title={isSubscriber ? 'Envoyez vos fichiers en illimité' : "Envoyez vos fichiers jusqu'à 10 Go"}
          className="mb-4 tablet:w-1/2 laptop:w-max font-bold font-iliad text-32 laptop:text-[48px]"
        />

        <p className="text-gray-600 laptop:text-20 mb-8">
          {!isSubscriber ? "C'est simple, gratuit et sécurisé !" : 'Réservé aux abonnés Free'}
        </p>

        <Lottie options={defaultOptions} alt="visuel home" autoplay="true" style={{ width: 300, cursor: 'default' }} />

        <Button
          variant="secondary"
          label="Transférer des fichiers"
          onClick={() => router.push('/upload')}
          className="w-full max-w-[230px] my-8 laptop:mb-14 laptop:font-regular"
        />
      </div>

      <div className="flex flex-col justify-center items-center bg-gray-50 rounded-24 py-4 px-1 laptop:flex-row w-full">
        {DETAIL_ITEMS.map(({ icon, label }) => (
          <div key={label} className="flex flex-row items-center p-4 w-full laptop:justify-center">
            <Icon name={icon} className="flex justify-center items-center text-24 laptop:text-32 pr-4" />
            <span className="text-left text-gray-700 text-14 laptop:text-16">{label}</span>
          </div>
        ))}
      </div>
    </>
  )
}

export default Presentation
