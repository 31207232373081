import React, { Fragment } from 'react'

export const TAG = {
  LIST: '##',
  ORDERED_LIST: '@@',
  LIST_ITEM: '--',
  SUB_ITEM: '__',
  BOLD: '**',
  LIGHT: '$$',
  CARRIAGE_RETURN: '\n',
  ITALIC: '££',
  IMG: '<img',
  URL: '<url',
  SPAN: '<span',
  SUP: '<sup'
}

export const REGEX = {
  LIST: /(##([\s\S]*?)##)/g, // to treat first
  ORDERED_LIST: /(@@([\s\S]*?)@@)/g, // to treat first
  LIST_ITEM: /(--.*?((?=--)|(?=##)|(?=@@)))/g, // to treat inside list
  LIST_ITEM_WITHOUT_SUB_ITEM: /(.*?(?=__))/, // to treat inside list
  SUB_ITEM: /(__.*?(?=__))|(__.*)/g, // to treat inside list
  CARRIAGE_RETURN: /(\n)/g, // to treat last
  BOLD: /(\*\*.*?\*\*)/g, // to treat last
  LIGHT: /(\$\$.*?\$\$)/g, // to treat last
  ITALIC: /(££.*?££)/g,
  IMG: /(<img.*?\/>)/g,
  URL: /(<url.*?\/>)/g,
  SPAN: /(<span.*?\/>)/g,
  SUP: /(<sup.*?\/>)/g
}

export const CAPS = 'CAPS'

export const deleteTag = (stringToTreat: string, tag: string): string | null => {
  if (!stringToTreat || !tag) {
    return null
  }
  return stringToTreat.slice(tag.length, stringToTreat.length - tag.length)
}

export const deleteBalise = (stringToTreat: string, tag: string): string | null => {
  if (!stringToTreat || !tag) {
    return null
  }
  return stringToTreat.slice(tag.length, stringToTreat.length - 2)
}

export const extractBaliseParams = (string: string | null): { [key: string]: any } => {
  const props = string ? string.trim().split('" ') : []
  return props.reduce((acc, fullProperty) => {
    const property = fullProperty.split('="')
    const value = property[1].slice(
      0,
      property[1].lastIndexOf('"') === property[1].length - 1 ? property[1].length - 1 : property[1].length
    )

    return {
      ...acc,
      [property[0]]: value
    }
  }, {})
}

export const encapsule = (string: string, caps: string): string => {
  return `${caps}${string}${caps}`
}

export const capsStructureInString = (string: string): string | null => {
  if (!string) {
    return null
  }

  return string
    .replace(REGEX.LIST, encapsule('$1', CAPS)) // $1 content matched with regex
    .replace(REGEX.ORDERED_LIST, encapsule('$1', CAPS))
}

export const enhanceBasicString = (string: string): string | null => {
  if (!string) {
    return null
  }

  return string
    .replace(REGEX.CARRIAGE_RETURN, encapsule('$1', CAPS)) // $1 content matched with regex
    .replace(REGEX.BOLD, encapsule('$1', CAPS))
    .replace(REGEX.LIGHT, encapsule('$1', CAPS))
    .replace(REGEX.IMG, encapsule('$1', CAPS))
    .replace(REGEX.ITALIC, encapsule('$1', CAPS))
    .replace(REGEX.URL, encapsule('$1', CAPS))
    .replace(REGEX.SPAN, encapsule('$1', CAPS))
    .replace(REGEX.SUP, encapsule('$1', CAPS))
}

export const createStringInJSX = (string: string): React.ReactNode | null => {
  if (!string) {
    return null
  }

  const stringSplit = string.split(CAPS)

  return (
    <Fragment>
      {stringSplit.map((element, index) => {
        if (element.indexOf(TAG.LIGHT) !== -1) {
          const elementWithoutTag = deleteTag(element, TAG.LIGHT)
          return <em key={index}>{elementWithoutTag}</em>
        } else if (element.indexOf(TAG.BOLD) !== -1 && element.lastIndexOf(TAG.BOLD) !== element.indexOf(TAG.BOLD)) {
          const elementWithoutTag = deleteTag(element, TAG.BOLD)
          return (
            <b key={index} className="font-bold">
              {elementWithoutTag}
            </b>
          )
        } else if (element.indexOf(TAG.CARRIAGE_RETURN) !== -1) {
          return <br key={index} />
        } else if (element.indexOf(TAG.ITALIC) !== -1) {
          const elementWithoutTag = deleteBalise(element, TAG.ITALIC)

          return (
            <i key={index} className={'parsed-italic'}>
              {elementWithoutTag}
            </i>
          )
        } else if (element.indexOf(TAG.IMG) !== -1) {
          const elementWithoutTag = deleteBalise(element, TAG.IMG)
          const elementProps = extractBaliseParams(elementWithoutTag)

          if (elementProps.src && elementProps.alt) {
            return <img key={index} className={'parsed-image'} src={elementProps.src} alt={elementProps.alt} />
          }

          return element
        } else if (element.indexOf(TAG.URL) !== -1) {
          const elementWithoutTag = deleteBalise(element, TAG.URL)
          const elementProps = extractBaliseParams(elementWithoutTag)

          if (elementProps.href && elementProps.label) {
            return (
              <a
                key={index}
                className={elementProps.className || 'parsed-url'}
                href={elementProps.href}
                target={elementProps.target}
                rel={elementProps.rel}
              >
                {elementProps.label}
              </a>
            )
          }

          return element
        } else if (element.indexOf(TAG.SPAN) !== -1) {
          const elementWithoutTag = deleteBalise(element, TAG.SPAN)
          const elementProps = extractBaliseParams(elementWithoutTag)

          if (elementProps.value) {
            return (
              <span key={index} className={elementProps.className ?? 'parsed-span'}>
                {elementProps.value}
              </span>
            )
          }

          return element
        } else if (element.indexOf(TAG.SUP) !== -1) {
          const elementWithoutTag = deleteBalise(element, TAG.SUP)
          const elementProps = extractBaliseParams(elementWithoutTag)

          if (elementProps.value) {
            return (
              <sup key={index} className={elementProps.className ?? 'parsed-sup'}>
                {elementProps.value}
              </sup>
            )
          }
          return element
        } else {
          return element
        }
      })}
    </Fragment>
  )
}

export const enhanceListString = (string: string): React.ReactNode | null => {
  const lists = string && string.match(REGEX.LIST)
  const orderedLists = string && string.match(REGEX.ORDERED_LIST)
  if (!string || (!lists && !orderedLists)) {
    return null
  }
  const items = string.match(REGEX.LIST_ITEM)

  if (!items) {
    return null
  }

  const itemsJSX = items.map((item, i) => {
    const itemCleaned = item.slice(TAG.LIST_ITEM.length).trim()
    const itemEnhanced = enhanceBasicString(itemCleaned)
    const itemStyled = itemEnhanced && createStringInJSX(itemEnhanced)

    const subItems = itemEnhanced?.match(REGEX.SUB_ITEM)
    if (!subItems) {
      return <li key={`li-no-subitem-${i}`}>{itemStyled}</li>
    }

    const itemWithouSubBullets = itemEnhanced?.match(REGEX.LIST_ITEM_WITHOUT_SUB_ITEM)?.[0]
    const itemWithouSubBulletsStyled = itemWithouSubBullets && createStringInJSX(itemWithouSubBullets)

    const subItemsJSX = subItems.map((item, j) => {
      const subItemCleaned = item.slice(TAG.SUB_ITEM.length).trim()
      const subItemEnhanced = enhanceBasicString(subItemCleaned)
      const subItemStyled = subItemEnhanced && createStringInJSX(subItemEnhanced)

      return <li key={`li-with-subitem-${i}-${j}`}>{subItemStyled}</li>
    })

    return (
      <div key={`div-${i}`}>
        <li>{itemWithouSubBulletsStyled}</li>
        {lists ? <ul>{subItemsJSX}</ul> : <ol>{subItemsJSX}</ol>}
      </div>
    )
  })

  return lists ? <ul>{itemsJSX}</ul> : <ol>{itemsJSX}</ol>
}

export const parseStringToJsx = (string?: string): React.ReactNode | null => {
  if (!string) {
    return null
  }

  const stringEnhanced = capsStructureInString(string)
  const stringSplit = stringEnhanced?.split(CAPS)

  return stringSplit
    ?.filter((element) => element.length)
    .map((element) => {
      if (element.match(REGEX.LIST) || element.match(REGEX.ORDERED_LIST)) {
        return <Fragment key={element}>{enhanceListString(element)}</Fragment>
      } else {
        const elementEnhanced = enhanceBasicString(element)
        return elementEnhanced && <Fragment key={element}>{createStringInJSX(elementEnhanced)}</Fragment>
      }
    })
}

export default parseStringToJsx
