import { E_PRICE_SIZE, PriceMonthly } from '@/components/ui/Price'
import { OptionTypeDatasBrutes } from '@/shared/types/plan'
import { Button, E_ELEMENT_PARSE_STRING, Icon, ParseString } from '@transverse/digitalsystems-library'
import clsx from 'clsx'
import React, { useState } from 'react'

type PlanCardType = {
  monthlyDiscount?: number
  monthlyPrice: number
  options: OptionTypeDatasBrutes[]
  name: string
  subtitleMobile?: string
  link?: string
  isSubscriber?: boolean
}

export const PlanCard = ({
  monthlyDiscount,
  monthlyPrice,
  options,
  name,
  subtitleMobile,
  link,
  isSubscriber
}: PlanCardType) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <div className="flex flex-col items-center rounded-16 border border-gray-100 w-full mb-4 py-4 px-8">
      {name !== 'Serie Free' ? (
        <h2 className="font-normal px-4 text-24 font-iliad text-gray-900">
          Forfait&nbsp;<span className="text-red-500">{name}</span>
        </h2>
      ) : (
        <h2 className="font-normal px-4 text-24 font-iliad text-gray-900">{name}</h2>
      )}

      <p className="text-14 text-gray-500">{subtitleMobile}</p>

      <PriceMonthly amount={monthlyPrice} size={E_PRICE_SIZE.SMALL} />

      {isSubscriber && monthlyDiscount && (
        <span className="text-status-success text-center text-14 py-1 px-[12px] w-fit font-semibold bg-[#DCFCE7] border-none rounded-full">
          {monthlyDiscount}€ d'économies par mois
        </span>
      )}

      <div className="flex flex-col items-center">
        <button onClick={handleOpen} className="flex items-center font-semibold text-blue-500 text-12 mt-4">
          Plus de détails <Icon name="arrow-drop-up-line" className={clsx('text-20', open && 'rotate-180')} />
        </button>
        {open && (
          <div className="flex flex-col mt-4 py-2 px-6">
            {options.map(({ label, detail, booster }: OptionTypeDatasBrutes) => (
              <div className="flex flex-col my-1" key={`plan-card-${label}`}>
                <ParseString type={E_ELEMENT_PARSE_STRING.SPAN} className="text-14">
                  {label}
                </ParseString>
                <p className="text-12">{detail}</p>
                {booster && <p className="text-14 text-red-500 font-bold ">{booster}€/mois</p>}
              </div>
            ))}
          </div>
        )}
      </div>

      <Button label="S'abonner" onClick={() => window.open(link, '_blank')} className="mt-6" />
      <span className="text-12 text-gray-500 text-center mt-2">Sans engagement</span>
    </div>
  )
}
