import clsx from 'clsx'
import { Icon } from '@transverse/digitalsystems-library'
import { IFAQItem } from '@/data/faq'
import { ParseString } from '@/components/ui/ParseString/ParseString'

type FAQItemProps = IFAQItem & { itemOpen: number; setItemOpen: (value: number) => void; id: number }

export const FAQItem = ({ question, answer, itemOpen, setItemOpen, id }: FAQItemProps) => {
  const open = itemOpen === id
  const handleOpen = () => {
    setItemOpen(itemOpen === id ? -1 : id)
  }

  return (
    <li className="flex flex-col text-14 border border-gray-100 rounded-12 text-left mb-2 last:mb-0 desktop:text-16 pr-6">
      <div
        className="flex flex-row items-center justify-between w-full cursor-pointer"
        role="button"
        tabIndex={0}
        onClick={handleOpen}
        onKeyDown={undefined}
        key={question}
      >
        <ParseString className="pl-6 py-4 m-0 font-semibold">{question}</ParseString>
        <Icon
          name="arrow-down-s-line"
          className={clsx('text-24 ml-4 cursor-pointer transition-all duration-500', open && 'rotate-180')}
        />
      </div>
      <span
        className={clsx(
          'overflow-hidden mr-0 desktop-sm:mr-30 w-full transition-max-h duration-500',
          open ? 'max-h-[1000px]' : 'max-h-0'
        )}
      >
        {answer && <ParseString className={'pb-4 px-6 m-0 parsed-paragraph text-left'}>{answer}</ParseString>}
      </span>
    </li>
  )
}
