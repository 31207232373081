export interface IFAQItem {
  question: string
  answer: string
}

export interface IFAQ {
  title: string
  collection: IFAQItem[]
}

export const FAQ_SECTION: IFAQ = {
  title: 'Besoin d’aide pour votre transfert ?',
  collection: [
    {
      question: "Qu'est-ce que Free Transfert ?",
      answer:
        "Free Transfert est un service d'envoi et de partage de fichiers, simple, gratuit et sécurisé destiné aussi bien aux particuliers qu'aux entreprises. \n Avec Free Transfert envoyez et transférez vos gros fichiers en quelques clics : photos, films, vidéos, documents..."
    },
    {
      question: 'Quelles sont les offres Free Transfert ?',
      answer:
        'Deux offres gratuites sont disponibles avec Free Transfert pour envoyer vos fichiers simplement : <span value="Free Transfert" className="font-semibold" /> et <span value="Free Transfert Premium (réservé aux abonnés Free)" className="font-semibold" />. \nComparer les fonctionnalités <url label="ici" href="/comparator" target="_blank" className="underline text-black" />'
    },
    {
      question: 'Quels sont les avantages de Free Transfert Premium et comment en bénéficier ?',
      answer:
        'Avec Free Transfert Premium vous pouvez envoyer vos fichiers sans limite de taille, jusqu\'à 50 destinataires et avec une durée de validité jusqu\'à 30 jours. Pour profiter de l’offre Free Transfert Premium, vous devez souscrire une <url label="offre mobile Free" href="https://mobile.free.fr/" target="_blank" className="underline text-black" /> ou un <url label="abonnement Freebox" href="https://www.free.fr/freebox/" target="_blank" className="underline text-black" />.'
    },
    {
      question: 'Qui peut utiliser Free Transfert ?',
      answer:
        'L’offre <span value="Free Transfert" className="font-semibold" /> est un service de partage de fichiers gratuit disponible pour tous. \nL’offre <span value="Free Transfert Premium" className="font-semibold" /> est réservée en exclusivité aux abonnés Free connectés depuis leur Freebox (connexion avec une adresse IP Free ou Free Pro) et aux abonnés mobile Free. \nPas encore abonné Free ? Découvrez nos offres Freebox <url label="ici" href="https://www.free.fr/freebox/" target="_blank" className="underline text-black" /> et nos offres mobiles <url label="ici" href="https://mobile.free.fr/" target="_blank" className="underline text-black" />.'
    },
    {
      question: 'Est-ce que Free Transfert est un service gratuit ?',
      answer: 'Oui, Free Transfert est 100% gratuit.'
    },
    {
      question: 'Puis-je récupérer un transfert expiré ?',
      answer: "Non, passé la date d'expiration, votre expéditeur devra réaliser à un nouvel envoi."
    },
    {
      question: 'Combien de transferts puis-je effectuer chaque jour ?',
      answer: 'Aucune limite, vous pouvez utiliser Free Transfert autant de fois que vous le souhaitez.'
    },
    {
      question: 'Est-ce que mes données sont sécurisées ?',
      answer:
        "Les fichiers envoyés et reçus avec Free Transfert sont cryptés avec le protocole https et stockés dans des data centers situés à Paris jusqu'à leur date d'expiration. \nIls ne sont accessibles que par l'expéditeur et son ou ses destinataire(s). Free Transfert permet également aux utilisateurs de créer un mot de passe pour accéder aux fichiers partagés. Dans ce cas pensez à communiquer vous-même le mot de passe à votre ou vos destinataire(s)."
    }
  ]
}
